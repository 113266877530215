import {useHistory} from 'react-router-dom'
interface Props {
    status?: 'success' | 'danger'
    onClose: (value: Boolean) => void
    title: string
    description?: string
    buttonText?: string
    open?: boolean
}
export default function Alert({
    status = 'success',
    onClose,
    title,
    description,
    buttonText = 'Confirmar',
    open,
}: Props) {
    const colors = {
        success: '#50cd89',
        danger: '#f1416c',
    }
    const history = useHistory()
    return (
        <div
            className='min-vw-100 min-vh-100 position-absolute top-0 start-0'
            style={{
                transition: 'opacity 2s',
                display: open ? 'block' : 'none',
                opacity: open ? '1' : '0',
            }}
        >
            <div
                className='min-vw-100 min-vh-100 position-absolute top-0 start-0'
                style={{backdropFilter: `blur(5px)`}}
            ></div>
            <div
                className={`shadow-lg alert alert-${status} position-absolute top-50 start-50 translate-middle py-10 px-10 px-lg-20 mb-10`}
            >
                <span className='w-100 d-flex justify-content-center py-5'>
                    <i
                        className={`fas fa-${status === 'success' ? 'check' : 'times'}-circle`}
                        style={{color: colors[status], fontSize: '50px'}}
                    ></i>
                </span>
                <div className='text-center'>
                    <h1 className='fw-bolder mb-5'>{title}</h1>
                    {description && (
                        <>
                            <div
                                className={`separator separator-dashed border-${status} opacity-25 mb-5`}
                            ></div>
                            <div className='mb-9 text-dark'>
                                {description}
                                <br />
                            </div>
                        </>
                    )}
                    <div className='d-flex flex-center flex-wrap'>
                        <button
                            onClick={() => {
                                onClose(false)
                                history.push('/')
                            }}
                            className={`btn btn-${status} m-2`}
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
