import {Spinner} from 'react-bootstrap-v5'

export function FallbackView() {
  return (
    <div className='d-flex justify-content-center align-items-center h-100'>
      <div className='row justify-content-center'>
        <Spinner animation='grow' className='text-primary' />
        <span className='fw-bold fs-2 text-center'>Carregando...</span>
      </div>
    </div>
  )
}
