import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import * as _redux from "./setup";
import store, { persistor } from "./setup/redux/Store";
import axios from "axios";
import { App } from "./app/App";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
const { PUBLIC_URL } = process.env;

_redux.setupAxios(axios, store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <App basename={PUBLIC_URL} />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
