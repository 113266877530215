import { Tooltip as BsTooltip } from "bootstrap"
import React, { useEffect, useRef } from "react"

export const Tooltip = (p: {children: JSX.Element, text: string, placement?: "auto" | "top" | "bottom" | "left" | "right" | (() => void) | undefined }) => {
    const childRef = useRef(undefined as unknown as Element)

    useEffect(() => {
        const placement = p.placement? p.placement : "auto"
        const t = new BsTooltip(childRef.current, {
            title: p.text,
            placement: placement,
            trigger: "hover"
        })
        return () => t.dispose()
    }, [p.text, p.placement])

    return React.cloneElement(p.children, { ref: childRef })
}