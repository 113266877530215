import {Field, FieldProps, FormikProps} from 'formik'
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
    className?: string
    checked?: boolean
    formik?: FormikProps<any>
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}
export function CheckBox({
    name,
    label,
    onChange,
    onBlur,
    formik,
    className,
    checked,
    ...props
}: Props) {
    return (
        <div className={`d-flex form-check form-check-custom form-check-solid ${className}`}>
            <Field name={name}>
                {({form}: FieldProps) => (
                    <input
                        {...props}
                        className='form-check-input'
                        type='checkbox'
                        id={name}
                        name={name}
                        checked={checked === undefined ? Boolean(form.values[name]) : checked}
                        onBlur={onBlur || form?.handleBlur}
                        onChange={() => form?.setFieldValue(name, !form.values[name])}
                    />
                )}
            </Field>
            {label && (
                <label
                    htmlFor={name}
                    className='form-label fs-6 fw-bolder m-0 h-100 d-flex align-items-center ms-2'
                >
                    {label}
                </label>
            )}
        </div>
    )
}
