import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export default function SettingsRouter() {
    const OfficeHourPage = lazy(() => import('../../pages/settings/office-hour/OfficeHourPage'))

    return (
        <Switch>
            <Route path='/configuracoes/expediente' component={OfficeHourPage} />
        </Switch>
    )
}
