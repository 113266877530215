import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import BillingRouter from './internal/BillingRouter'
import {DashboardRouter} from './internal/DashboardRouter'
import ManagementsRouter from './internal/ManagementsRouter'
import {ProviderRouter} from './internal/ProviderRouter'
import SettingsRouter from './internal/SettingsRouter'
import {SupportRouter} from './internal/SupportRouter'
import PrincipalRouter from './internal/PrincipalRouter'

export function PrivateRoutes() {
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

    const ProceduresPage = lazy(() => import('../pages/procedures/ListProcedures'))
    const ProfileClinic = lazy(() => import('../pages/providers/clinics/ProfileClinic'))
    const TermsAndConditions = lazy(
        () => import('../pages/terms-and-conditions/TermsAndConditions')
    )

    const AuditPage = lazy(() => import('../pages/audit/ListAudit'))

    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                <Route path='/principal' component={PrincipalRouter} />

                <Route path='/account' component={AccountPage} />
                <Route path='/estabelecimento' component={ProfileClinic} />
                <Route path='/termos-e-condicoes' component={TermsAndConditions} />

                <Route path='/exames' component={ProceduresPage} />

                <Route path='/prestadores' component={ProviderRouter} />

                <Route path='/gestao' component={ManagementsRouter} />

                <Route path='/financeiro' component={BillingRouter} />

                <Route path='/auditoria' component={AuditPage} />

                <Route path='/suporte' component={SupportRouter} />

                <Route path='/configuracoes' component={SettingsRouter} />

                <Route path='/' component={DashboardRouter} />

                <Redirect from='/autenticacao/entrar' to='/dashboard/administrativo' />

                <Redirect to='/error/404' />
            </Switch>
        </Suspense>
    )
}
