import React from 'react'

const HeaderTextWidget: React.FC = ({children}) => {
  return (
    <div className='mb-5 bg-light p-5 rounded text-center'>
        <span className='text-dark h3 text-justify'>
            {children}
        </span>
    </div>
  )
}

export {HeaderTextWidget}