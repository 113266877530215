import {Link} from 'react-router-dom'

interface Props {
    buttonUrl: string
    buttonTitle?: string
}
export default function ButtonAuthCancel({buttonUrl, buttonTitle = 'Cancelar'}: Props) {
    return (
        <Link to={buttonUrl}>
            <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-secondary w-100'
            >
                {buttonTitle}
            </button>
        </Link>
    )
}
