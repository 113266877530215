import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export enum PrincipalRouterEnum {
    DatatableSchedule = '/principal/agenda',

    DatatableConsult = '/principal/consultas',
    ShowConsult = '/principal/consultas/:id',

    DatatableExam = '/principal/exames',
    ShowExam = '/principal/exames/:id',

    DatatableCoupon = '/principal/cupons',
    ShowCoupon = '/principal/cupons/:id',
    RegisterCoupon = '/principal/cupons/cadastro',
}

export default function PrincipalRouter() {
    const DatatableSchedule = lazy(() => import('../../pages/schedule/SchedulePage'))

    const DatatableConsult = lazy(() => import('../../pages/consults/DatatableConsult'))
    const ShowConsult = lazy(() => import('../../pages/consults/ProfileConsult'))

    const ShowSolicitationExam = lazy(
        () => import('../../pages/solicitations/exams/ShowSolicitation')
    )
    const ListSolicitationsExams = lazy(
        () => import('../../pages/solicitations/exams/ListSolicitations')
    )

    const DatatableCoupon = lazy(() => import('../../pages/consults/coupons/DatatableCoupons'))
    const ShowCoupon = lazy(() => import('../../pages/consults/coupons/ShowCoupon'))
    const RegisterCoupon = lazy(() => import('../../pages/consults/coupons/RegisterCoupon'))

    return (
        <Switch>
            {/*Rotas para Agenda*/}
            <Route path={PrincipalRouterEnum.DatatableSchedule} component={DatatableSchedule} />

            {/*Rotas para Consultas*/}
            <Route path={PrincipalRouterEnum.ShowConsult} component={ShowConsult} />
            <Route path={PrincipalRouterEnum.DatatableConsult} component={DatatableConsult} />

            {/*Rotas para Exames*/}
            <Route path={PrincipalRouterEnum.ShowExam} component={ShowSolicitationExam} />
            <Route path={PrincipalRouterEnum.DatatableExam} component={ListSolicitationsExams} />

            {/*Rotas para Cupons*/}
            <Route path={PrincipalRouterEnum.RegisterCoupon} component={RegisterCoupon} />
            <Route path={PrincipalRouterEnum.ShowCoupon} component={ShowCoupon} />
            <Route path={PrincipalRouterEnum.DatatableCoupon} component={DatatableCoupon} />
        </Switch>
    )
}
