import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export default function BillingRouter() {
    const ApprovedNotes = lazy(() => import('../../pages/billing/fiscalNotes/ApprovedNotes'))
    const PendingNotes = lazy(() => import('../../pages/billing/fiscalNotes/PendingNotes'))

    const WalletClinicPage = lazy(() => import('../../pages/billing/wallets/ListClinicWallet'))
    const WalletDoctorPage = lazy(() => import('../../pages/billing/wallets/ListDoctorWallet'))

    return (
        <Switch>
            <Route path='/financeiro/notas-fiscais/aprovadas' component={ApprovedNotes} />
            <Route path='/financeiro/notas-fiscais/pendentes' component={PendingNotes} />

            <Route path='/financeiro/conta-corrente/estabelecimento' component={WalletClinicPage} />
            <Route path='/financeiro/conta-corrente/profissional' component={WalletDoctorPage} />
        </Switch>
    )
}
