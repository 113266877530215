import {useHistory} from 'react-router-dom'
export function ButtonGoBack() {
    const history = useHistory()

    return (
        <button
            type='button'
            onClick={() => history.goBack()}
            className='btn btn-sm btn-secondary ms-2'
        >
            <i className='fas fa-arrow-alt-circle-left'></i>
            Voltar
        </button>
    )
}
