import clsx from 'clsx'
import {Field, FieldProps} from 'formik'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
    type?: string
    value?: string | undefined
    eye?: string
    toggleEye?: (e: EventTarget, currState: string) => void
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    required?: boolean
}
export function TextInput({
    name,
    label,
    type = 'text',
    value,
    eye,
    toggleEye,
    onChange,
    onBlur,
    required = false,
    ...props
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <Field>
                {({form}: FieldProps) => (
                    <div className="input-group flex-nowrap flex-column">
                        <div className="input-group flex-nowrap">
                            <input
                                {...props}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': form?.touched[name] && form?.errors[name],
                                    },
                                    {
                                        'is-valid': form?.touched[name] && form?.errors[name],
                                    }
                                )}
                                type={type}
                                id={name}
                                name={name}
                                value={value || form.values[name]}
                                onChange={onChange || form.handleChange}
                                onBlur={onBlur || form.handleBlur}
                            />
                            {eye && toggleEye && (
                                <button className="btn btn-outline-secondary bg-light" onClick={(e) => toggleEye(e.target, eye)} type="button"><i className={eye}></i></button>
                            )}
                        </div>
                        
                        {form.touched[name] && form.errors[name] && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                        {form.errors[name]}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Field>
        </>
    )
}
