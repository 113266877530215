import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function ProviderRouter() {
    const ListDoctors = lazy(() => import('../../pages/providers/doctors/ListDoctors'))
    const RegisterDoctor = lazy(() => import('../../pages/providers/doctors/RegisterDoctor'))
    const ProfileDoctors = lazy(() => import('../../pages/providers/doctors/ProfileDoctor'))
    const Services = lazy(() => import('../../pages/providers/clinics/components/ServicesClinic'))

    const ListOccupation = lazy(
        () => import('../../pages/providers/doctors/occupation/ListOccupation')
    )
    const RegisterOccupation = lazy(
        () => import('../../pages/providers/doctors/occupation/RegisterOccupation')
    )
    const EditOccupation = lazy(
        () => import('../../pages/providers/doctors/occupation/EditOccupation')
    )
    const ListTypesDoctor = lazy(() => import('../../pages/providers/doctors/type/ListType'))

    // const ListProducts = lazy(() => import('../../pages/providers/product/ListProducts'))
    const RegisterProducts = lazy(() => import('../../pages/providers/product/RegisterProduct'))
    const EditProducts = lazy(() => import('../../pages/providers/product/EditProduct'))
    const ListSynonyms = lazy(() => import('../../pages/providers/product/synonyms/ListSynonyms'))
    const RegisterSynonyms = lazy(
        () => import('../../pages/providers/product/synonyms/RegisterSynonyms')
    )
    const ListTypeProduct = lazy(() => import('../../pages/providers/product/type/ListType'))
    const RegisterTypeProduct = lazy(
        () => import('../../pages/providers/product/type/RegisterType')
    )
    const EditTypeProduct = lazy(() => import('../../pages/providers/product/type/EditType'))
    return (
        <Switch>
            {/* Profissões */}
            <Route
                path='/prestadores/profissionais/profissoes/editar/:id'
                component={EditOccupation}
            />
            <Route
                path='/prestadores/profissionais/profissoes/cadastro'
                component={RegisterOccupation}
            />
            <Route path='/prestadores/profissionais/profissoes' component={ListOccupation} />
            <Route
                path='/prestadores/profissionais/tipo_profissionais'
                component={ListTypesDoctor}
            />
            {/* Profissionais */}
            <Route path='/prestadores/profissionais/cadastro' component={RegisterDoctor} />
            <Route path='/prestadores/profissionais/:id' component={ProfileDoctors} />

            <Route path='/prestadores/profissionais' component={ListDoctors} />
            {/* Produtos */}
            <Route
                path='/prestadores/produtos/tipo_produtos/editar/:id'
                component={EditTypeProduct}
            />
            <Route
                path='/prestadores/produtos/tipo_produtos/cadastro'
                component={RegisterTypeProduct}
            />
            <Route path='/prestadores/produtos/tipo_produtos' component={ListTypeProduct} />

            <Route path='/prestadores/produtos/sinonimos/cadastro' component={RegisterSynonyms} />
            <Route path='/prestadores/produtos/sinonimos' component={ListSynonyms} />
            <Route path='/prestadores/produtos/edit/:id' component={EditProducts} />
            <Route path='/prestadores/produtos/cadastro' component={RegisterProducts} />
            <Route path='/prestadores/servicos' component={Services} />
        </Switch>
    )
}
