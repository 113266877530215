import axios from 'axios'
import clsx from 'clsx'
import Pusher from 'pusher-js'
import {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {User} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import Swal from 'sweetalert2'
import {HeaderSelectClinic, HeaderUserMenu} from '../../../partials'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu} from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import {Tooltip} from '../../../../app/modules/components'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

interface Message {
    id: string
    type: string
    notifiable_type: string
    notifiable_id: number
    color?: string
    icon?: string
    data: {
        title: string
        message: string
        description: string
    }
    read_at: null | Date
    created_at: string
    updated_at: Date
}

const Topbar: FC = () => {
    const [notifications, setNotifications] = useState<Message[]>([])
    const [someNotRead, setSomeNotRead] = useState(false)
    const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User

    const clinic_id: number = useSelector<RootState>(
        ({auth}) => auth.clinic_id,
        shallowEqual
    ) as number

    async function getNotification() {
        const {data} = await axios.get('/profile/notifications')
        setNotifications(data.data.notifications)
        setSomeNotRead(data.data?.notifications?.some((item: Message) => !item.read_at))
    }

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            forceTLS: false,
            authEndpoint: process.env.REACT_APP_PUSHER_APP_ENDPOINT,
            authTransport: 'ajax',
            auth: {
                params: {
                    email: user.email,
                },
            },
        })

        const channel = pusher.subscribe(`private-App.Models.User.${user.id}`)
        channel.bind(process.env.REACT_APP_PUSHER_EVENT_NAME || '', function (data: any) {
            console.log(data.data)
            Swal.fire({
                icon: data.icon || 'success',
                title: data.title,
                html: data.description,
                confirmButtonText: 'Recebido!',
                allowOutsideClick: false,
            })
            var audio = new Audio(toAbsoluteUrl('/common/sounds/alert.mp3'))
            audio.play()
            getNotification()
        })

        return () => {
            pusher.unsubscribe(`private.App.Models.User.${user.id}`)
        }
    }, [user])

    useEffect(() => {
        getNotification()
    }, [])

    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                id='kt_header_clinic_menu_toggle'
            >
                <div
                    className={clsx('cursor-pointer w-100 d-flex align-items-center')}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                >
                    <h5 className='text-light px-2 m-0 text-overflow'>
                        {user.clinics.filter((clinic) => clinic.id === clinic_id)[0].nm_fantasy}
                    </h5>
                    <i className='text-white fas fa-caret-down' />
                </div>
                <HeaderSelectClinic />
            </div>
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                <Tooltip text='Notificações' placement='bottom'>
                    <div
                        className={'btn btn-icon btn-active-dark position-relative'}
                        data-kt-menu-trigger='click'
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='bottom'
                    >
                        <i className='text-light fas fa-bell'></i>
                        {someNotRead && (
                            <>
                                <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
                                <span className='pulse-ring'></span>
                            </>
                        )}
                    </div>
                </Tooltip>

                <HeaderNotificationsMenu notifications={notifications} />
            </div>
            <div
                className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                id='kt_header_user_menu_toggle'
            >
                <Tooltip text='Configurações de perfil' placement='bottom'>
                    <div
                        className={clsx(
                            'cursor-pointer symbol btn btn-icon btn-active-dark',
                            toolbarUserAvatarHeightClass
                        )}
                        data-kt-menu-trigger='click'
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='bottom'
                    >
                        <i className={'text-light fas fa-user-circle'}></i>
                    </div>
                </Tooltip>

                <HeaderUserMenu />
            </div>
        </div>
    )
}

export {Topbar}
